.status {
  .ant-tag {
    width: 100px;
    text-align: center;
  }

  .ant-btn-sm {
    font-size: 11px;
  }
}
