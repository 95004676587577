.questionnaire-list {

}

.modal-confirm-content {
  list-style-type: none;
  padding-inline-start: 0;
  padding: 0 48px;
  li {
    div {
      display: flex;
      justify-content: space-between;
      span:first-child::after {
        content: " :"
      }
      span {
        display: flex;
        font-weight: bold;
      }
    }
  }
}
