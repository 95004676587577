@import 'mq';

.ant-page-header-heading-sub-title {
  display: none;
  @media @md {
    display: block;
  }
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}
