.register-distribution {

  &-title {
    margin-bottom: 16px;
  }


  &-date-picker {
    display: flex;
    margin-bottom: 24px;
    align-items: center;

    &-row {
      display: flex;
    }
  }

  &-list {

  }
}
