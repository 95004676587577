@import '/src/assets/less/mq';
@import 'src/assets/less/setting';

.questionnaire-thanks {
  .ant-result-extra {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.questionnaire-thanks-subtext {
  font-size: 16px;
}

.questionnaire-thanks-title {
  font-size: 24px;
  word-break: keep-all;
}

@media @md {
  .wbr {
    display: none;
  }
}

a.ant-btn {
  height: 100%;
  padding: 6px 15px !important;
  line-height: 1.3;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  small {
    font-size: 10px;
  }
}

.questionnaire-thanks-procedure {
  margin-top: 16px;
  display: block;
  line-height: 1.3;

  &:hover,
  &:focus {
    background-color: transparent;
  }

  .rounded {
    vertical-align: top;
    margin-left: 8px;
    margin-top: 2px;
    display: inline-block;
    width: 14px;
    height: 14px;
    text-align: center;
    font-size: 70%;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.65);
  }
}

.questionnaire-thanks-modal {
  .ant-modal-body {
    > * {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  img {
    max-width: 100%;
  }

  .ant-btn {
    height: 48px;
  }

  .description {
    padding: 32px 0;
    font-size: 16px;
    text-align: center;

    .number {
      margin-right: 10px;
      color: #00AEEF;
    }
  }
}
