@border-radius: 8px;
@import "/src/assets/less/mq";
@import "src/assets/less/setting";

.questionnaire {
  &-root {
    margin: auto;
    width: 640px;
    max-width: 90vw;
    padding: 12px 0;
  }

  &-title {
    margin-bottom: 16px;
    border-radius: @border-radius;
    position: relative;

    &__header {
      border-top-right-radius: @border-radius;
      border-top-left-radius: @border-radius;
      height: 10px;
      background-color: @livable-blue;
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% + 2px);
    }
  }

  &-form {
    &-content {
      margin-bottom: 32px;
    }

    &__text {
      font-size: 16px;
      font-weight: 400;
      white-space: pre-line;

      &-wrapper {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    &-flow {
      border: solid 1px #e4e4e4;
      border-radius: 4px;
      padding: 16px;

      &__text {
        font-size: 16px;
        font-weight: 400;

        &-wrapper {
          margin-bottom: 16px;
          @media @sm {
            text-align: center;
          }
        }
      }

      .ant-form-item {
        margin-bottom: 8px;
      }

      .ant-form-item-explain {
        margin-top: 8px;
        @media @sm {
          text-align: center;
        }
      }
      
      &-item {
        &__radio {
          display: flex;
          justify-content: space-between;
        }

        &__button {
          height: 30px;
          line-height: 30px;
          display: flex;
          justify-content: center;
        }

        &__triangle {
          text-align: center;
          margin-top: 8px;
        }
      }
    }

    &-item {
      &-radio-button {
        height: 30px;
        line-height: 30px;
        margin-bottom: 16px;
        margin-right: 16px;
        justify-content: space-between;
      }

      &__wrapper {
        text-align: center;
        margin: auto;
      }

      &__first {
        margin-bottom: 16px;
      }

      &-slider-label {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        @media @sm {
          font-size: 16px;
        }
      }
    }

    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
}
