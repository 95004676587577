.client-delivery-list {

  &-questionnaire-title{
    margin-bottom: 16px;
  }

  &-questionnaire-delivery-date{
    margin-bottom: 16px;
  }

}
