.questionnaire-delivery-duplicate-alert-modal {

  &-alert-title {
    color: #f5222d;
    font-weight: bold;
  }

  &-title {
    font-weight: bold;
  }


  &-second-title {
    color: #f5222d;
    font-weight: bold;
    margin-top: 40px;
  }



  &-text{
    color: #f5222d;
  }

}
