.login-form {
    max-width: 400px;
    margin: auto;
}

.login-form-forgot {
    float: right;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.login-form-button {
    width: 100%;
}
